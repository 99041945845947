import { Fragment } from "react";

import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

import Chip from "@/components/Chip";
import { INVENTORY_STATUS_MAP } from "@/constants";
import { formatInventoryDate } from "@/lib/helpers";

const setColor = (isOn) => {
  return isOn ? "green" : "off";
};

const StatusBoard = ({
  status,
  enrollment,
  purchase,
  appDeadline,
  enrollDeadline,
}) => {
  const statusEntries = Object.entries(INVENTORY_STATUS_MAP).filter(
    (entry) => !entry.includes("canceled"),
  );
  const pastEnrollmentDeadline = Date.now() > new Date(enrollDeadline);
  return status === "canceled" ? (
    <Chip color="red" label={INVENTORY_STATUS_MAP[status]} />
  ) : (
    <div
      style={{
        display: "grid",
        gap: ".75rem 4px",
        gridTemplateColumns: "1fr auto 1fr auto 1fr auto 1fr auto 1fr auto 1fr",
        marginTop: "2.5rem",
        width: "100%",
      }}
    >
      <div style={{ gridRow: 1, gridColumn: 4, position: "relative" }}>
        <Typography
          as="span"
          variant="caption"
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            transform: "translateX(-50%)",
          }}
        >
          {formatInventoryDate(appDeadline)}
        </Typography>
      </div>
      <div style={{ gridRow: 1, gridColumn: 8, position: "relative" }}>
        <Typography
          as="span"
          variant="caption"
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            transform: "translateX(-50%)",
          }}
        >
          {formatInventoryDate(enrollDeadline)}
        </Typography>
      </div>
      {statusEntries.map(([code, label], i) => (
        <Fragment key={code}>
          <Chip
            style={{ gridRow: 2, gridColumn: i * 2 + 1 }}
            label={label}
            color={setColor(code === status)}
          />
          {i < statusEntries.length - 1 ? (
            <Divider
              flexItem
              orientation="vertical"
              sx={{
                gridRow: "2 / 5",
                gridColumn: i * 2 + 2,
                margin: "0 .25rem",
              }}
            />
          ) : null}
        </Fragment>
      ))}

      <Chip
        style={{ gridColumn: "3 / 6", gridRow: 3, zIndex: 10 }}
        label="Available for Purchase"
        color={setColor(purchase)}
      />
      <Chip
        style={{ gridColumn: "3 / 8", gridRow: 4, zIndex: 10 }}
        label="Website Enrollment"
        color={setColor(!pastEnrollmentDeadline)}
      />
      <Chip
        style={{ gridColumn: "9", gridRow: 4, zIndex: 10 }}
        label="Admin Enrollment Only"
        color={setColor(pastEnrollmentDeadline && enrollment)}
      />
    </div>
  );
};

export default StatusBoard;
