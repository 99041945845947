import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";

import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import Chip from "@/components/Chip";
import CopyToClipboardButton from "@/components/CopyToClipboardButton";
import Data from "@/components/Data";
import { useDrawer } from "@/components/Drawer";
import InventoryCancelDrawer from "@/components/Drawers/InventoryCancelDrawer";
import useAlert from "@/hooks/use-alert";
import { formatDate } from "@/lib/helpers";
import Catalogue from "@/models/catalogue";
import { fetchCohortEnrollments, fetchLmsSections } from "@/models/enrollment";
import {
  deleteInventoryItem,
  fetchInventoryItem,
  migrateToCanvas,
  promoteToMarket,
} from "@/models/inventory";
import { fetchPartner } from "@/models/partner";

import ButtonStack from "./components/ButtonStack";
import ConfirmationDialog from "./components/ConfirmationDialog";
import DataStripe from "./components/DataStripe";
import DetailsHeader from "./components/DetailsHeader";
import EnrollmentsTable from "./components/EnrollmentsTable";
import { DataListLoading } from "./components/Loading";
import StatusBoard from "./components/StatusBoard";

const decorateEnrollments = function (enrollments) {
  return enrollments.map((e) => {
    return {
      ...e,
      studentName: `${e.student?.firstName} ${e.student?.lastName}`,
      location: e.student?.address?.country,
      orderId: e.order?._id,
    };
  });
};

const InventorySearchShow = function InventorySearchShow({ handleEdit }) {
  const { _id } = useParams();
  const showAlert = useAlert();
  const queryClient = useQueryClient();
  const inventoryCancelDrawer = useDrawer(InventoryCancelDrawer);
  const [openDialog, setOpenDialog] = useState(false);

  // Data Fetching -------------------------------------------------------------
  const inventoryItem = useQuery({
    queryKey: ["inventory", _id],
    queryFn() {
      return fetchInventoryItem(_id);
    },
  });

  const catalogueItem = useQuery({
    queryKey: ["catalogue", inventoryItem.data?.catalogueItem._id],
    queryFn() {
      return Catalogue.findByID(inventoryItem.data?.catalogueItem._id);
    },
    enabled: Boolean(inventoryItem.data),
  });

  const partner = useQuery({
    queryKey: ["partners", inventoryItem.data?.partner?._id],
    queryFn: fetchPartner,
    enabled: Boolean(inventoryItem.data),
  });

  const enrollmentData = useQuery({
    queryKey: ["cohorts", _id],
    queryFn: fetchCohortEnrollments,
  });

  const courseSections = useQuery({
    queryKey: [
      "courseSections",
      { partnerId: inventoryItem.data?.partner?._id, inventoryId: _id },
    ],
    queryFn: fetchLmsSections,
    enabled: Boolean(inventoryItem.data),
  });
  // Event Handlers ------------------------------------------------------------

  const deleteMutation = useMutation({
    mutationFn(data) {
      return deleteInventoryItem(_id, data?.newInventoryItem);
    },
    onSuccess() {
      inventoryCancelDrawer.remove();
      setOpenDialog(false);
      showAlert({
        message: `Successfully deleted inventory item`,
        duration: null,
      });
      queryClient.invalidateQueries({
        queryKey: ["inventory"],
      });
    },
    onError(error) {
      console.log(error);
      showAlert({ message: "An error has occurred", severity: "error" });
    },
  });

  const promoteMutation = useMutation({
    mutationFn() {
      return promoteToMarket(inventoryItem.data);
    },
    onSuccess() {
      setOpenDialog(false);
      showAlert({
        message: `Successfully promoted inventory item`,
        duration: null,
      });
      queryClient.invalidateQueries({
        queryKey: ["inventory"],
      });
    },
    onError(error) {
      console.log(error);
      showAlert({ message: "An error has occurred", severity: "error" });
    },
  });

  const migrateMutation = useMutation({
    mutationFn() {
      return migrateToCanvas(inventoryItem.data);
    },
    onSuccess() {
      setOpenDialog(false);
      queryClient.invalidateQueries({ queryKey: ["courseJob"] });
      showAlert({
        message: `Course queued for creation in Canvas`,
        duration: null,
      });
    },
    onError(error) {
      console.log(error);
      showAlert({ message: "An error has occurred", severity: "error" });
    },
  });

  const handleCancel = () => {
    if (enrollmentData.data?.enrollments?.length) {
      inventoryCancelDrawer.show({
        cancel() {
          inventoryCancelDrawer.remove();
        },
        inventoryItem: inventoryItem.data,
        isSaving: deleteMutation.isPending,
        onSubmit(data) {
          deleteMutation.mutate(data);
        },
      });
    } else if (enrollmentData.data?.enrollments?.length === 0) {
      setOpenDialog("cancel");
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const canvasUrl =
    partner.data &&
    `${partner.data?.linkLMS}/courses/sis_integration_id:${_id}`;

  return (
    <Box sx={{ pt: 3 }}>
      <DetailsHeader
        inventoryItem={inventoryItem}
        seatsFilled={enrollmentData.data?.enrollments?.length}
      />

      {inventoryItem.data && (
        <ButtonStack
          inventoryItem={inventoryItem}
          handleCancel={handleCancel}
          setOpenDialog={setOpenDialog}
          handleEdit={handleEdit}
          courseSections={courseSections}
        />
      )}

      <br />

      <ConfirmationDialog
        openDialog={openDialog}
        handleClose={handleClose}
        mutation={
          openDialog === "cancel"
            ? deleteMutation
            : openDialog === "promote"
              ? promoteMutation
              : migrateMutation
        }
      />

      {inventoryItem.isLoading ? <DataListLoading /> : null}

      {inventoryItem.data ? (
        <Box className="data-stripe-wrapper" sx={{ mb: 5 }}>
          <DataStripe
            color="#73B991"
            label="MSRP"
            content={`$${catalogueItem.data?.msrp}`}
          />
          <DataStripe
            color="#73B991"
            label="Retail Price"
            content={`$${inventoryItem.data.retailPrice}`}
          />
          <DataStripe
            color="#EDBCB0"
            label="Created"
            content={formatDate(inventoryItem.data.createdAt)}
          />
        </Box>
      ) : null}

      {inventoryItem.data && (
        <>
          <Data>
            <Data.Item>
              <Data.Label>Canvas Onboarding:</Data.Label>
              <Data.Content>
                {inventoryItem.data.lmsEnrollmentRequested ? (
                  <Chip
                    color="green"
                    label="Completed"
                    size="small"
                    icon={<CheckIcon />}
                  />
                ) : (
                  <Chip label="Not started" size="small" />
                )}
              </Data.Content>
            </Data.Item>
            <Data.Item>
              <Data.Label>ID:</Data.Label>
              <Data.Content>
                <code>{inventoryItem.data._id}</code>
              </Data.Content>
              <CopyToClipboardButton text={inventoryItem.data._id} />
            </Data.Item>
            <Data.Item>
              <Data.Label sx={{ flexShrink: 0 }}>Express Link:</Data.Label>
              {catalogueItem.data ? (
                <>
                  <Data.Content>
                    {partner.isLoading && <div>Loading...</div>}
                    {partner.data && (
                      <code>{`${partner.data.domain}/express/${inventoryItem.data._id}`}</code>
                    )}
                  </Data.Content>
                  {partner.data && (
                    <CopyToClipboardButton
                      text={`${partner.data.domain}/express/${inventoryItem.data._id}`}
                    />
                  )}
                </>
              ) : null}
            </Data.Item>
            {courseSections.isSuccess ? (
              <Data.Item>
                <Data.Label>Canvas Course Link:</Data.Label>
                <Data.Content>
                  {partner.data ? (
                    <a href={canvasUrl} target="_blank" rel="noreferrer">
                      {canvasUrl}
                    </a>
                  ) : (
                    "Loading..."
                  )}
                </Data.Content>
              </Data.Item>
            ) : null}
          </Data>
          <StatusBoard
            status={inventoryItem.data.status}
            enrollment={inventoryItem.data.availableForEnrollment}
            purchase={inventoryItem.data.availableForPurchase}
            appDeadline={inventoryItem.data.applicationDeadline}
            enrollDeadline={inventoryItem.data.enrollmentDeadline}
          />
        </>
      )}
      <br />
      <br />
      <br />
      {enrollmentData.isFetching && !enrollmentData.data ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CircularProgress size="1.25rem" sx={{ mr: 1.5 }} /> Loading
          enrollments...
        </Box>
      ) : null}
      {enrollmentData.data?.enrollments && catalogueItem.data?.partner && (
        <EnrollmentsTable
          captionTitle={`${enrollmentData.data.enrollments.length} Enrollment${
            enrollmentData.data.enrollments?.length === 1 ? "" : "s"
          }`}
          enrollments={decorateEnrollments(enrollmentData.data.enrollments)}
          inventoryId={_id}
          partnerId={catalogueItem.data.partner._id}
          courseSections={courseSections}
        />
      )}
      <br />
    </Box>
  );
};

export default InventorySearchShow;
